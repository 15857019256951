// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ka58YuR20", "avIqAQi11"];

const serializationHash = "framer-lGIZ8"

const variantClassNames = {avIqAQi11: "framer-v-14ezk31", ka58YuR20: "framer-v-15lm1j9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.6, ease: [0.25, 1, 0.5, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Line Animation Active": "avIqAQi11", "Line Animation": "ka58YuR20"}

const getProps = ({background, height, id, width, ...props}) => { return {...props, nhvtjR0LA: background ?? props.nhvtjR0LA ?? "rgb(0, 0, 0)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ka58YuR20"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nhvtjR0LA, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ka58YuR20", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-15lm1j9", className, classNames)} data-framer-name={"Line Animation"} layoutDependency={layoutDependency} layoutId={"ka58YuR20"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({avIqAQi11: {"data-framer-name": "Line Animation Active"}}, baseVariant, gestureVariant)}><motion.div className={"framer-oapcmf"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"h49bc1FBm"} style={{backgroundColor: nhvtjR0LA}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lGIZ8.framer-metk8, .framer-lGIZ8 .framer-metk8 { display: block; }", ".framer-lGIZ8.framer-15lm1j9 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 787px; }", ".framer-lGIZ8 .framer-oapcmf { bottom: 0px; flex: none; left: -20px; position: absolute; top: 0px; width: 1%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lGIZ8.framer-15lm1j9 { gap: 0px; } .framer-lGIZ8.framer-15lm1j9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lGIZ8.framer-15lm1j9 > :first-child { margin-left: 0px; } .framer-lGIZ8.framer-15lm1j9 > :last-child { margin-right: 0px; } }", ".framer-lGIZ8.framer-v-14ezk31 .framer-oapcmf { left: 0px; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 787
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"avIqAQi11":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nhvtjR0LA":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWrv92NlZp: React.ComponentType<Props> = withCSS(Component, css, "framer-lGIZ8") as typeof Component;
export default FramerWrv92NlZp;

FramerWrv92NlZp.displayName = "Line Animation - Career Section";

FramerWrv92NlZp.defaultProps = {height: 1, width: 787};

addPropertyControls(FramerWrv92NlZp, {variant: {options: ["ka58YuR20", "avIqAQi11"], optionTitles: ["Line Animation", "Line Animation Active"], title: "Variant", type: ControlType.Enum}, nhvtjR0LA: {defaultValue: "rgb(0, 0, 0)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerWrv92NlZp, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})